@import '../../assets/stylesheets/base/_variables';

.modalContainer {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.6);
  font-size: 15px;
  font-weight: bold;
  color: #62626e;
}

.modalContent {
  padding: 20px;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  height: auto;
  width: 60%;

  @media #{$mobile} {
    width: 90%;

    &.fullScreenMobile {
      width: 100%;
      border-radius: 0;
      height: 100vh;
      overflow: scroll;
      padding: 20px 0;
    }
  }
}

.modalHeader {
  padding: 9px 10px;
  padding-left: 20px;
  height: 38px;
  font-size: 15px;
  position: relative;
}

.closeBtn {
  padding: 0;
  background: transparent;
  border: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;

  @media #{$mobile} {
    &.fullScreenMobile {
      left: 20px;
      right: initial;
    }
  }
}

.modalBody {
  // flex-grow: 1;
  padding: 15px 0;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;

  :nth-child(2) {
    margin-left: 20px;
  }
}

.mobileArrowLeftIcon {
  display: none;

  @media #{$mobile} {
    display: block;
  }
}

.mobileTimesIcon {
  @media #{$mobile} {
    display: none;
  }
}

// classes for CSSTransition
.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 0.3s ease;
}
